import React, { useState, useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Form } from 'react-bootstrap';
import { getRoutes } from '../../utils/api/routes';
import DateSelector from '../../components/FormInputs/DateSelector';
import {
  formatLongDate, isDateInRange, getTimeInHoursAndMins, formatShortDate,
} from '../../utils/dates';
import TextInput from '../../components/FormInputs/TextInput';
import SpinnerButton from '../../components/FormInputs/SpinnerButton';
import { withContextProvider } from '../../hoc';
import { ToastProvider } from '../../providers';
import { useToasts } from '../../hooks';
import { handleResponse } from '../../utils/api/errorHandler';
import BuildRoute from '../../components/BuildRoute';

const RoutesList = ({ currentRoutes, organizationId }) => {
  // Hook Toast
  const { showToast } = useToasts();
  const [sorting, setSorting] = useState({ field: 'date', order: 'desc' });
  const [routeName, setRouteName] = useState('');
  const [startDate, setstartDate] = useState('');
  const [endDate, setendDate] = useState('');
  const [filteredRoutes, setFilteredRoutes] = useState(currentRoutes);
  const [loading, setLoading] = useState(false);
  // Ref to handle Search button
  const searchButtonRef = useRef();
  const [emptyMessage, setEmptyMessage] = useState('No routes found');

  const handleSortingChange = (sortString) => {
    const [field, order] = sortString.split('_');
    setSorting({ field, order });
  };

  const onRouteSearch = () => {
    const routesStartDate = startDate ? formatLongDate(startDate) : '';
    const routesEndDate = endDate ? formatLongDate(endDate) : '';
    const params = {
      routeName,
      routesStartDate: startDate ? formatLongDate(startDate) : '',
      routesEndDate: endDate ? formatLongDate(endDate) : '',
      sort_by: sorting.field,
      direction: sorting.order,
    };

    // Only validate date range if both startDate and endDate values are provided
    if (routesStartDate && routesEndDate) {
      const pass = isDateInRange(startDate, endDate);
      if (!pass) {
        showToast({
          message: 'Please enter a valid date range. Start date must fall before end date',
          type: 'error',
        });

        return;
      }
    }

    setLoading(true);

    getRoutes(organizationId, params)
      .then(handleResponse)
      .then(data => {
        if (Array.isArray(data.routes) && data.routes.length === 0) {
          setEmptyMessage('No results found. Please expand your search criteria.');
        }
        setFilteredRoutes(data.routes);
      })
      .catch((error) => {
        showToast({ message: error.message, type: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    onRouteSearch();
  }, [sorting.field, sorting.order]);

  const onInputClear = () => {
    setstartDate('');
    setendDate('');
    setRouteName('');
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <div className="form-inline form-group mr-2">
          <Form.Label className="mr-2">Route Name</Form.Label>
          <TextInput
            name="name"
            value={routeName}
            setValue={setRouteName}
            onKeyEnterPressed={() => searchButtonRef.current.click()}
          />
        </div>
        <div className="form-inline form-group mr-2">
          <Form.Label className="mr-2">Date from</Form.Label>
          <DateSelector
            name="route-time"
            value={startDate}
            setValue={setstartDate}
            shouldDefaultDate={false}
            onKeyEnterPressed={() => searchButtonRef.current.click()}
          />
        </div>
        <div className="form-inline form-group mr-2">
          <Form.Label className="mr-2">to</Form.Label>
          <DateSelector
            name="route-time"
            value={endDate}
            setValue={setendDate}
            shouldDefaultDate={false}
            onKeyEnterPressed={() => searchButtonRef.current.click()}
          />
        </div>
        <div className="form-inline form-group">
          <SpinnerButton
            ref={searchButtonRef}
            className="button align-self-baseline"
            label="Search"
            isLoading={loading}
            onClick={onRouteSearch}
          />
        </div>
      </div>
      <div className="d-flex flex-column mr-4">
        <div className="d-flex flex-row justify-content-between">
          <BuildRoute organizationId={organizationId} />
          <button type="button" className="btn btn-link" onClick={() => onInputClear()}>Clear Fields</button>
        </div>
        <div className="d-flex flex-row margin-left-flex">
          <select
            name="ordering"
            id="ordering"
            className="form-control order-dropdown"
            value={`${sorting.field}_${sorting.order}`}
            onChange={(e) => handleSortingChange(e.target.value)}
          >
            <option value="" disabled selected>Sort by</option>
            <option value="date_desc">Date Desc</option>
            <option value="date_asc">Date Asc</option>
            <option value="name_asc">Name A-Z</option>
            <option value="name_desc">Name Z-A</option>
          </select>
        </div>
      </div>
      <ul className="list-group list-group_container mt-3">
        {filteredRoutes.length ? (filteredRoutes.map((route) => (
          <div key={route.id} className="m-1">
            <li className="list-group-item">
              <div className="row">
                <div className="col-8 d-flex flex-column justify-content-center">
                  <a href={`/routes/${route.id}/edit?mode=map`}>
                    <h6 className="text-success clickableText font-weight-bold">{route.name}</h6>
                  </a>
                  <small className="m-0">{route.description}</small>
                </div>
                <div className="col-2 d-flex flex-column align-items-end justify-content-center mr-0">
                  <small className="font-weight-bold">{formatShortDate(route.date)}</small>
                  <small className="font-weight-bold">{route.number_of_stops} sites</small>
                </div>
                <div className="col-2 d-flex flex-column align-items-end  justify-content-center ">
                  <small>{route.total_distance && `${(route.total_distance).toFixed(2)} mi`}</small>
                  <small className="font-weight-bold">{getTimeInHoursAndMins(route.total_time)}</small>
                </div>
              </div>
            </li>
          </div>
        ))) : (
          <div className="d-flex justify-content-center">
            <p className="font-weight-bold">{emptyMessage}</p>
          </div>
        )}
      </ul>
    </>
  );
};

RoutesList.defaultProps = {
  currentRoutes: [],
};

RoutesList.propTypes = {
  currentRoutes: PropTypes.array,
  organizationId: PropTypes.number.isRequired,
};

export default withContextProvider(ToastProvider)(RoutesList);
