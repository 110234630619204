import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AccordionBody from '../AccordionBody';
import RouteModal from '../RouteModal';
import { removeRoute } from '../../utils/api/routes';
import { redirectToRoutesIndex } from '../../utils/navigation';
import { getItemStyle, getListStyle, reorder } from '../../utils/dragDrop';
import { formatShortDate, getTimeInHoursAndMins } from '../../utils/dates';
import { useDialogs, useRoutes, useToasts } from '../../hooks';
import DragDropContextComponent from '../DragAndDrop/DragDropContext';
import DraggableItem from '../DragAndDrop/DraggableItem';
import SidebarItem from './SidebarItem';
import { handleResponse } from '../../utils/api/errorHandler';
import MenuButton from '../FormInputs/MenuButton';
import { styles } from './Sidebar.style';

const Sidebar = ({ route }) => {
  const {
    name,
    description,
    date,
    stops,
    organizationId,
    totalDistance,
    totalTime,
  } = route;
  const [items, setItems] = useState(stops);
  const [isDragDisabled, setIsDragDisabled] = useState(false);

  const { openDialog, closeDialog } = useDialogs();

  const { removeStop, reorderStops } = useRoutes();

  const [errorRouteMessage, setErrorRouteMessage] = useState('');
  // Hook Toast
  const { showToast } = useToasts();
  // useEffect to track chanes in the route stops in context
  useEffect(() => {
    // action on update of stops
    setItems(stops);
    // if there is a NaN value in distance, show error message
    stops.map((stop, i) => {
      if (Number.isNaN(Number(stop.distance)) && i > 0) {
        return setErrorRouteMessage(<div className="mx-1"><small>Sorry, we could not calculate driving directions from <b>{stops[i - 1].site_name}</b> to <b>{stops[i].site_name}</b></small></div>);
      }
      return setErrorRouteMessage('');
    });
  }, [stops]);

  const onDragEnd = (result) => {
    // Disable the dragging until response is coming
    setIsDragDisabled(true);
    // if item dropped outside the dropable area
    if (!result.destination) {
      setIsDragDisabled(false);
      return;
    }
    // if item is droped in same position
    if (result.destination.index === result.source.index) {
      setIsDragDisabled(false);
      return;
    }
    // find dragged routeStop
    const routeStop = stops.find(
      (stop) => stop.id === parseInt(result.draggableId, 10),
    );
    // get reordered items with new position we use -1 bc our positions start with 1 but index of array start with 0
    const reorderedItems = reorder(
      items,
      result.source.index - 1,
      result.destination.index - 1,
    );
    /*
      we want to still update state before response comes bc getting response is taking time
      we store Items to set it back if there is an error acured while updating server
    */
    const storedItems = items;
    // set reorderted items to state
    setItems(reorderedItems);
    // make an api call to update server with new position

    reorderStops(routeStop.id, result.destination.index)
      .catch((error) => {
        // set items back to previous state if there is problem with updating database
        setItems(storedItems);
        // dispacth error to toast message
        showToast({ message: error.message, type: 'error' });
      })
      .finally(() => {
        // enable the dragging after response came either fulfilled or rejected
        setIsDragDisabled(false);
      });
  };

  // State to open/close route modal
  const [openRouteModal, setOpenRouteModal] = useState(false);

  /**
   * Open RouteModal to edit a route
   */
  const handleEdit = () => {
    setOpenRouteModal(true);
  };

  /**
   * Handle remove route stop
   */
  const handleDeleteStop = async (stopId) => {
    removeStop(stopId);
    closeDialog();
  };

  /**
   * Open a modal with custom message
   */
  const handleOpenDeleteModal = (stop) => {
    openDialog({
      message: `Do you want to delete the stop ${stop.site_name}?`,
      show: true,
      actions: [
        {
          label: 'Yes',
          onClick: () => {
            handleDeleteStop(stop.id);
            closeDialog();
          },
        },
        {
          label: 'No',
          variant: 'danger',
          onClick: closeDialog,
        },
      ],
    });
  };

  /**
   * Handle remove site from route
   */
  const handleDeleteRoute = (e, routeId) => {
    e.preventDefault();
    removeRoute(routeId, route.organizationId)
      .then(handleResponse)
      .then(() => {
        closeDialog();
        redirectToRoutesIndex();
      })
      .catch((error) => {
        closeDialog();
        showToast({ message: error.message, type: 'error' });
      });
  };

  /**
   * Open a modal with custom message for Route Delete
   */
  const handleOpenRouteDeleteModal = (e, routeId) => {
    openDialog({
      message: 'Do you want to delete this Route?',
      show: true,
      actions: [
        {
          label: 'Yes',
          onClick: () => {
            handleDeleteRoute(e, routeId);
            closeDialog();
          },
        },
        {
          label: 'No',
          variant: 'danger',
          onClick: closeDialog,
        },
      ],
    });
  };

  // create draggable items using DraggableItem and passing children component
  const draggableItems = items.map((item) => (
    <DraggableItem
      key={item.id}
      item={item}
      isDragDisabled={isDragDisabled}
      getItemStyle={getItemStyle}
    >
      <SidebarItem stop={item} onDelete={handleOpenDeleteModal} />
    </DraggableItem>
  ));

  const routeActionItems = [
    { label: 'Edit', onClick: () => handleEdit() },
    { label: 'Delete', onClick: (e) => handleOpenRouteDeleteModal(e, route.routeId) },
  ];
  return (
    <div className="container m-2 sidebar_container">
      <div className="row">
        <div className="d-flex col justify-content-left">
          <h5 className="font-weight-bold m-2">{name}</h5>
        </div>
        <div className="d-flex col justify-content-end">
          <MenuButton items={routeActionItems} />
        </div>
      </div>

      <AccordionBody title="Route Details" eventKey="route-details">
        <div className="row">
          <div className="col-5 d-flex flex-column">
            <small className="m-0">{description}</small>
          </div>
          <div className="col-3 d-flex flex-column text-right">
            <small className="m-0">{formatShortDate(date)}</small>
            <small className="m-0">{`${stops.length} sites`}</small>
          </div>
          <div className="col-4 d-flex flex-column text-right">
            <small>{totalDistance && `${totalDistance.toFixed(2)} mi`}</small>
            <small className="m-0 font-weight-bold">
              {getTimeInHoursAndMins(totalTime)}
            </small>
          </div>
        </div>
      </AccordionBody>
      {isDragDisabled ? (
        <p style={styles.loadingMessage}>
          Calculating Distance and Time. Please wait...
        </p>
      ) : null}
      <DragDropContextComponent
        draggableItems={draggableItems}
        onDragEnd={onDragEnd}
        getListStyle={getListStyle}
      />
      <RouteModal
        isOpen={openRouteModal}
        organizationId={organizationId}
        currentRoute={route}
        onClose={() => setOpenRouteModal(false)}
      />
      {errorRouteMessage }
    </div>
  );
};

Sidebar.propTypes = {
  route: PropTypes.object.isRequired,
};

export default Sidebar;
