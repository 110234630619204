import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContext } from '@react-google-maps/api';
import { getMarker } from '../../utils/api/site';
import SiteInfoWindow from '../SiteAdmin/SitesInMap/SiteInfoWindow';
import VamosMarker from './VamosMarker';
import { RoutesContext } from '../../context';

const SitesInMarkers = ({
  sitesArray, addStop, setBoundsChanged, isButtonClicked, setButtonClicked,
}) => {
  const mapContext = React.useContext(MapContext);
  const routesContext = React.useContext(RoutesContext);
  const markersRef = React.useRef([]);
  const [visibleSites, setVisibleSites] = useState([]);
  const [sitesInBounds, setSitesInBounds] = useState([]);

  const getCoordsFromObj = (obj) => {
    let latitude;
    let longitude;
    if (obj.coords) {
      latitude = obj.coords.lat;
      longitude = obj.coords.lng;
    } else if (obj.location.latitude && obj.location.longitude) {
      latitude = obj.location.latitude;
      longitude = obj.location.longitude;
    }
    return [latitude, longitude];
  };

  useEffect(() => {
    let boundsListener;
    if (mapContext) {
      const updateSitesInBounds = () => {
        const bounds = mapContext.getBounds();
        if (!bounds) return;
        const filteredSites = sitesArray.filter(site => {
          const [latitude, longitude] = getCoordsFromObj(site);
          const siteLatLng = new window.google.maps.LatLng(latitude, longitude);
          return bounds.contains(siteLatLng);
        });
        setSitesInBounds(filteredSites);

        if (typeof setBoundsChanged === 'function') {
          setBoundsChanged(true);
        }
      };

      const initialBounds = mapContext.getBounds();
      if (initialBounds) {
        const initialVisibleSites = sitesArray.filter(site => {
          const [latitude, longitude] = getCoordsFromObj(site);
          const siteLatLng = new window.google.maps.LatLng(latitude, longitude);
          return initialBounds.contains(siteLatLng);
        });
        setVisibleSites(initialVisibleSites.length > 0 ? initialVisibleSites : sitesArray);
        setSitesInBounds(initialVisibleSites);
      } else {
        setVisibleSites(sitesArray);
      }

      boundsListener = mapContext.addListener('idle', () => {
        updateSitesInBounds();
      });
    }
    return () => boundsListener?.remove();
  }, [mapContext, sitesArray, setBoundsChanged]);

  useEffect(() => {
    if (routesContext) {
      routesContext.setVamosMarkersRefs(markersRef);
    }
  }, [routesContext]);

  useEffect(() => {
    if (isButtonClicked) {
      setVisibleSites(sitesInBounds);
      setButtonClicked(false);
      setBoundsChanged(false);
    }
  }, [isButtonClicked, setButtonClicked, setBoundsChanged, sitesInBounds]);

  return (
    <>
      {visibleSites.length && visibleSites.map((site) => {
        const [latitude, longitude] = getCoordsFromObj(site);

        let siteId;
        if (site.site_id) {
          siteId = site.site_id;
        } else {
          siteId = site.id;
        }

        const siteMarkerIcon = getMarker({
          priority: site.priority, status: site.status, position: site?.position, icon: site.icon,
        });
        return (
          <VamosMarker
            key={`${site.id}}`}
            siteId={siteId}
            ref={el => { markersRef.current[siteId] = el; }}
            lat={latitude}
            lng={longitude}
            icon={siteMarkerIcon}
          >
            <SiteInfoWindow
              site={site}
              addStop={addStop}
            />
          </VamosMarker>
        );
      })}
    </>
  );
};

SitesInMarkers.defaultProps = {
  sitesArray: [],
  addStop: undefined,
  setBoundsChanged: false,
};

SitesInMarkers.propTypes = {
  sitesArray: PropTypes.array,
  addStop: PropTypes.func,
  setBoundsChanged: PropTypes.func,
  setButtonClicked: PropTypes.func.isRequired,
  isButtonClicked: PropTypes.bool.isRequired,
};

export default SitesInMarkers;
