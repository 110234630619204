import React from 'react';
import PropTypes from 'prop-types';
import { DirectionsRenderer } from '@react-google-maps/api';
import { useRoutes } from '../../hooks';
import SitesInMarkers from './SitesInMarkers';

const DirectionsSegments = ({ directions }) => {
  // Hook useRoute to have access to RoutesContext
  const { currentRoute } = useRoutes();

  /**
       * Stop missing properties:
       * - h2a
       * - lastVisit
       * - arrival
       * - departure
       * - worker_count
       * - priority (Just for marker colors)
       * - status (Just for marker colors)
       */

  /**
       * Stop different names:
       * - site_name | name
       */

  const ICONS_BASE_URL = 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=';
  return (
    <>
      { directions && (
        <>
          <SitesInMarkers sitesArray={currentRoute.stops} />
          <DirectionsRenderer
            directions={directions}
            options={{
              suppressMarkers: true,
            }}
            panel={document.getElementById('panel')}
            onLoad={(data) => {
              const callback = (mutationList) => {
                mutationList.forEach(mutation => {
                  if (mutation.addedNodes.length) {
                    const allMarkers = mutation.addedNodes[0].querySelectorAll('[class^="adp-marker');
                    if (currentRoute.stops.length === allMarkers.length) {
                      currentRoute.stops.forEach((route, index) => {
                        allMarkers[index].src = `${ICONS_BASE_URL}${route.position}|FE6256`;
                      });
                    }
                  }
                });
              };
              const observer = new MutationObserver(callback);
              const config = { childList: true };
              observer.observe(data.panel, config);
            }}
          />
        </>
      )}
    </>
  );
};

DirectionsSegments.defaultProps = {
  directions: undefined,
};

DirectionsSegments.propTypes = {
  directions: PropTypes.object,
};

export default DirectionsSegments;
