import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  GoogleMap,
  useJsApiLoader,
  InfoBox,
} from '@react-google-maps/api';
import { VamosMapProvider } from '../../providers';
import { styles } from './VamosMap.component.style';
import CustomButton from './CustomButton';

const VamosMap = ({
  isEnabled,
  setIsEnabled,
  isBoundsChanged,
  isButtonClicked,
  setButtonClicked,
  height,
  width,
  center,
  zoom,
  className,
  children,
  mapStyle,
}) => {
  const [infoBoxPosition, setInfoBoxPosition] = useState(center);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const infoBoxOption = { closeBoxURL: '', enableEventPropagation: true, boxStyle: { width: 'auto' } };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAP_JS_API_KEY,
  });
  const options = {
    minZoom: 3,
  };

  const closeInfoBox = () => {
    setShowInfoBox(false);
  };

  const handleButtonClick = () => {
    setIsEnabled(false);
    setButtonClicked(true);
  };

  return (
    isLoaded ? (
      <VamosMapProvider>
        <div className={className}>
          <GoogleMap
            mapContainerStyle={mapStyle || { height, width }}
            zoom={zoom}
            options={options}
            center={center}
            onRightClick={(data) => {
              setInfoBoxPosition({
                lat: data.latLng.lat(),
                lng: data.latLng.lng(),
              });
              setShowInfoBox(true);
            }}

          >
            <InfoBox
              options={infoBoxOption}
              position={infoBoxPosition}
              visible={showInfoBox}
            >
              <div style={styles.infoBoxContainer}>
                <button type="button" aria-label="Close" onClick={closeInfoBox} style={styles.close}> X </button>
                <div style={styles.infoText}>
                  latitude: {infoBoxPosition.lat}
                </div>
                <div style={styles.infoText}>
                  longitude: {infoBoxPosition.lng}
                </div>
                <a href={`/sites/new?lat=${infoBoxPosition.lat}&lng=${infoBoxPosition.lng}`} target="_blank" rel="noreferrer">
                  <button
                    type="button"
                    className="mt-2 btn btn-outline-primary"
                    onClick={() => { setShowInfoBox(false); }}
                    data-toggle="modal"
                    style={styles.button}
                  >
                    Create site
                  </button>
                </a>
              </div>
            </InfoBox>
            {!isEnabled && isBoundsChanged && <CustomButton onClick={handleButtonClick} isButtonClicked={isButtonClicked} />}
            {children}
          </GoogleMap>
        </div>
      </VamosMapProvider>
    ) : <></>
  );
};

VamosMap.defaultProps = {
  children: <></>,
  className: '',
  height: '500px',
  width: '100%',
  zoom: 4,
  center: {
    lat: 36.95,
    lng: -100.33,
  },
  mapStyle: undefined,
};

VamosMap.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  center: PropTypes.object,
  zoom: PropTypes.number,
  mapStyle: PropTypes.object,
  isEnabled: PropTypes.bool.isRequired,
  setIsEnabled: PropTypes.func.isRequired,
  isButtonClicked: PropTypes.bool.isRequired,
  setButtonClicked: PropTypes.func.isRequired,
  isBoundsChanged: PropTypes.bool.isRequired,
};

export default React.memo(VamosMap);
