import React from 'react';
import { PropTypes } from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import CustomFieldShow from '../../../CustomField/CustomFieldShow';
import SiteField from './SiteField/index';
import { redirectToSiteEdit, redirectToUrl } from '../../../../utils/navigation';
import { styles } from './SiteInfo.component.style';
import { getH2ANameFromH2AValue, removeSite } from '../../../../utils/api/site';
import { useCurrentUser, useDialogs, useToasts } from '../../../../hooks';
import MenuButton from '../../../FormInputs/MenuButton';
import { accessToDeleteBtn } from '../../../../utils/accessToDeleteBtn';
import { formatNumericDate } from '../../../../utils/dates';

const SiteInfo = ({
  site,
  values,
  modelFormItems,
}) => {
  // Hook Toast
  const { showToast } = useToasts();
  const { openDialog, closeDialog } = useDialogs();
  const { isManager: currentRoleIsManager } = useCurrentUser();
  const coordinatesText = `${site.latitude}, ${site.longitude}`;
  const tooltipContent = site.coords_set_manually
    ? 'These coordinates were manually updated by a Vamos user.'
    : 'These coordinates were automatically geocoded based on the address field. To manually update them, drag the marker on map or enter in new coordinates on Site Edit page.';
  const attributes = {
    'Site ID': site.id,
    'Creation Date': formatNumericDate(site.created_at),
    'Alternate ID(s)': site.alternate_ids,
    'Alternate Name(s)': site.alternate_names,
    Description: site.description,
    'Site Type': site.site_type,
    Units: site.units,
    'Worker count': site.worker_count,
    Arrival: site.arrival ? formatNumericDate(site.arrival) : null,
    Departure: site.departure ? formatNumericDate(site.departure) : null,
    H2A: getH2ANameFromH2AValue(site.h2a) || 'No Data',
    'Address line 1': site.address1,
    'Address line 2': site.address2,
    City: site.city,
    County: site.county,
    State: site.state,
    'Zip Code': site.zip_code,
    Region: site.region,
    Coordinates: (
      <>
        {coordinatesText}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="coordinates-tooltip">{tooltipContent}</Tooltip>}
        >
          <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: '5px' }} />
        </OverlayTrigger>
      </>
    ),
    Directions: site.directions,
    Memo: site.memo,
    Status: site.status,
  };

  let count = 0;
  const siteRef = [];
  const onClickDown = () => {
    siteRef[count].scrollIntoView();
    if (count < siteRef.length - 1) {
      count += 1;
    }
  };

  const onClickUp = () => {
    siteRef[count].scrollIntoView();
    if (count > 0) {
      count -= 1;
    }
  };

  const onEditClicked = (e) => {
    e.preventDefault();
    redirectToSiteEdit(site.id);
  };
  const onDeleteSite = (s) => {
    const formData = {
      organization_id: s.organization_id,
      sites: [
        { id: s.id },
      ],
    };
    removeSite(formData, s.id)
      .then(res => res.json())
      .then((response) => {
        if (!response.success) {
          closeDialog();
          throw new Error(response.error);
        }
        showToast({ message: 'The site has been deleted successfully', type: 'success' });
        // This allows us to return to the previous page
        redirectToUrl(document.referrer);
      })
      .catch((error) => {
        showToast({ message: error.message, type: 'error' });
        closeDialog();
      });
  };

  const handleOpenDeleteModal = () => {
    openDialog({
      message: [`Are you sure you want to delete site: ${site.name}?`, <br />, 'All associated notes and visits will also be deleted.'],
      show: true,
      actions: [
        {
          label: 'Yes',
          onClick: () => onDeleteSite(site),
        },
        {
          label: 'No',
          variant: 'danger',
          onClick: closeDialog,
        },
      ],
    });
  };

  const actionItemButtons = [
    { label: 'Edit', onClick: (e) => onEditClicked(e) },
    { label: 'Delete', onClick: () => handleOpenDeleteModal(site) },
  ];
  const actionItems = accessToDeleteBtn(actionItemButtons, currentRoleIsManager);

  return (
    <div className="row">
      <div className="col">
        <div className="d-flex pt-4 pb-5 justify-content-end">
          <MenuButton items={actionItems} />
        </div>
        <div className="site-info">
          <div className="info-link info-link-up mt-4" role="button" tabIndex={0} onClick={onClickUp} onKeyDown={onClickUp}>
            <i className="fa fa-2x fa-angle-double-up" style={styles.infoLink} />
          </div>
          {Object.keys(attributes).map((item, index) => (
            <div key={`${item}`} ref={(ref) => { siteRef[index] = ref; }}>
              <SiteField name={item} attribute={attributes[item]} />
            </div>
          ))}
          <CustomFieldShow values={values} modelFormItems={modelFormItems} />
          <div className="info-link" role="button" tabIndex={0} onClick={onClickDown} onKeyDown={onClickDown}>
            <i className="fa fa-2x fa-angle-double-down" style={styles.downIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

SiteInfo.propTypes = {
  site: PropTypes.object.isRequired,
  modelFormItems: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
};

export default SiteInfo;
