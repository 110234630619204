/* eslint-disable prefer-const */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { ToastProvider } from '../../../providers';
import { withContextProvider } from '../../../hoc';
import { removeCustomField, updateCustomField } from '../../../utils/api/site';
import { useToasts } from '../../../hooks';
import DragDropContextComponent from '../../DragAndDrop/DragDropContext';
import DraggableItem from '../../DragAndDrop/DraggableItem';
import { getFormItemStyle, getFormListStyle, reorder } from '../../../utils/dragDrop';
import DialogModal from '../../DialogModal';
import { redirectToCustomFieldsEdit } from '../../../utils/navigation';
import { capitalizeFirstLetter } from '../../../utils/api';

const CustomFieldFormEditPage = ({
  modelFormId, modelFormName, modelFormItems, isManager, associatedObjects,
}) => {
  const { showToast } = useToasts();
  const [formItems, setFormItems] = useState(modelFormItems);
  const [alertBody, setAlertBody] = useState({
    show: false,
    title: '',
    message: '',
    shouldShowCheckbox: false,
    actions: [],
    footer: '',
  });
  const [deleteItemIds, setDeleteItemIds] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isDragDisabled, setIsDragDisabled] = useState(false);

  const onDragEnd = (result) => {
    // Disable the dragging until response is coming
    setIsDragDisabled(true);
    // if item dropped outside the droppable area
    if (!result.destination) {
      setIsDragDisabled(false);
      return;
    }
    // if item is dropped in same position
    if (result.destination.index === result.source.index) {
      setIsDragDisabled(false);
      return;
    }
    // get reordered items with new position we use -1 bc our positions start with 1 but index of array start with 0
    const reorderedItems = reorder(formItems, result.source.index - 1, result.destination.index - 1);
    // convert 0-based index back to the 1-based position
    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      position: index + 1,
    }));

    setFormItems(updatedItems);
    setIsDragDisabled(false);
  };

  const headerFormName = capitalizeFirstLetter(modelFormName);

  const handleFieldChange = (event, item, property) => {
    let tempValue = [...formItems];
    const index = tempValue.findIndex(obj => obj.key === item.key);
    if (property === 'options') {
      tempValue[index].values = event.target.value.split(',').map(val => ({ value: val.trim() }));
      tempValue[index].options_string = event.target.value;
    } else {
      tempValue[index][property] = event.target.value;
    }
    setFormItems(tempValue);
  };

  const handleCheckboxChange = (event, item, property) => {
    let tempValue = [...formItems];
    const index = tempValue.findIndex(obj => obj.key === item.key);
    tempValue[index][property] = event.target.checked;
    setFormItems(tempValue);
  };

  const getCommaSeparatedValues = (valuesArray) => {
    const values = valuesArray.map(object => object.value);
    const commaSeparatedString = values.join();
    return commaSeparatedString;
  };

  const inputRenderHelper = (inputType, item) => {
    switch (inputType) {
      case 'checkbox-group':
      case 'select':
        return (
          <>
            <input
              key={item.key}
              type="text"
              placeholder="label"
              value={item.label}
              onChange={(e) => handleFieldChange(e, item, 'label')}
              className="form-control"
            />
            <input
              key={item.key}
              type="text"
              placeholder="options"
              value={getCommaSeparatedValues(item?.values ?? [])}
              onChange={(e) => handleFieldChange(e, item, 'options')}
              className="select-show form-control mt-2"
            />
          </>
        );
      default:
        // applicable to the remaining cases: text, number, heading, line, date
        return (
          <input
            key={item.key}
            type="text"
            placeholder="label"
            value={item.label}
            onChange={(e) => handleFieldChange(e, item, 'label')}
            className="form-control"
          />
        );
    }
  };

  const handleAddField = () => {
    // temporary key to avoid duplicates
    const timestamp = new Date().getTime();
    const formItemsToAdd = [{
      id: undefined,
      key: timestamp,
      label: undefined,
      position: formItems.length + 1,
      active: true,
      encrypt: false,
      required: false,
      type: undefined,
      values: undefined,
    }];
    setFormItems([...formItems, ...formItemsToAdd]);
  };

  const validateInputs = (items) => {
    // use Set instead of Array to avoid duplicates
    const inputErrors = new Set();
    items.forEach((item) => {
      if (!item.type && !inputErrors.has('type')) {
        inputErrors.add("Form items type can't be blank");
      }
      if (!item.label && !inputErrors.has('label')) {
        inputErrors.add("Form items label can't be blank");
      }
      if ((item.type === 'select' || item.type === 'checkbox-group') && !item.values && !inputErrors.has('options')) {
        inputErrors.add("Form items options can't be blank");
      }
    });
    return Array.from(inputErrors);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validateInputs(formItems);

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const requestData = {
        model_form: {
          form_items_attributes: formItems.map((item) => ({
            id: item.id,
            model_form_id: modelFormId,
            position: item.position,
            type: item.type,
            label: item.label,
            options_string: item.options_string,
            active: item.active,
            encrypt: item.encrypt,
            required: item.required,
          })),
        },
      };
      const formId = modelFormId;

      // delete items on save
      await Promise.all(deleteItemIds.map(async (itemId) => {
        const formItemId = {
          form_item_id: itemId,
        };
        await removeCustomField(itemId, formItemId);
      }));

      // update form items on save
      const response = await updateCustomField(formId, requestData);

      if (response.status === 200) {
        // reload page and showToast
        redirectToCustomFieldsEdit(formId);
        showToast({ message: 'A new form was updated.', type: 'success' });
      }
    } catch (error) {
      showToast({ message: error.message, type: 'error' });
    }
  };

  const handleDeleteField = (item) => {
    setFormItems((prevFormItems) => prevFormItems.filter((formItem) => formItem.key !== item.key || formItem.id !== item.id));
  };

  const handleCloseDeleteModal = () => {
    setAlertBody({ show: false });
  };

  const formItemIds = [];

  associatedObjects.forEach((subArray) => {
    subArray.forEach((item) => {
      formItemIds.push(item.form_item_id);
    });
  });

  const modalMessage = (itemId) => {
    const formItemFound = formItems.find((item) => item.id === itemId);

    if (formItemFound) {
      const { label } = formItemFound;
      const objectCount = formItemIds.filter((id) => id === itemId).length;

      if (objectCount > 1) {
        return `Custom field "${label}" has ${objectCount} associated ${modelFormName}s. Any data currently associated with this field will be permanently deleted.`;
      } if (objectCount === 1) {
        return `Custom field "${label}" has ${objectCount} associated ${modelFormName}. Any data currently associated with this field will be permanently deleted.`;
      }
    }

    return 'Are you sure you want to remove this custom field?';
  };

  const handleOpenDeleteModal = (item) => {
    // items with ids are previously saved items, while items without ids are currently being added with handleAddField function
    const itemId = item.id !== undefined ? item.id : item.key;
    const message = modalMessage(itemId);
    const footerMessage = message.includes('permanently deleted')
      ? 'Do you still want to remove this field?'
      : null;
    // if previously saved item, display modal
    if (item.id) {
      setAlertBody({
        message: `${message}`,
        show: true,
        actions: [
          {
            label: 'Yes',
            onClick: () => {
              handleDeleteField(item);
              setDeleteItemIds((prevDeleteItemIds) => [...prevDeleteItemIds, itemId]);
              handleCloseDeleteModal();
            },
          },
          {
            label: 'No',
            variant: 'danger',
            onClick: handleCloseDeleteModal,
          },
        ],
        footer: footerMessage,
      });
    } else {
      handleDeleteField(item);
    }
  };

  return (
    <>
      {errors.length > 0 && (
      <div className="alert alert-danger">
        <h6>{`${errors.length} errors prohibited this field from being saved:`}</h6>
        <ul>
          {errors.map((error) => {
            const timestamp = new Date().getTime();
            return (<li key={timestamp}>{error}</li>);
          })}
        </ul>
      </div>
      )}

      <h1 className="text-center font-weight-bold pt-2">{headerFormName} Form</h1>

      <div className="build_form_heading">
        <div className="row">
          <div className="col-2">Type</div>
          <div className="col-2">Label</div>
          <div className="col-1">Active</div>
          <div className="col-1">Encrypt</div>
          <div className="col-1">Required</div>
          {isManager ? (<div className="col-2">Delete</div>) : <></>}
        </div>
      </div>

      <DialogModal
        isVisible={alertBody.show}
        title={alertBody.title}
        message={alertBody.message}
        onClose={handleCloseDeleteModal}
        actions={alertBody.actions}
        footer={alertBody.footer}
      />

      <div className="model_form">
        <DragDropContextComponent
          onDragEnd={onDragEnd}
          getListStyle={getFormListStyle}
          draggableItems={formItems.map((item) => (
            <DraggableItem
              key={item.key}
              draggableId={String(item.key)}
              item={item}
              isDragDisabled={isDragDisabled}
              getItemStyle={getFormItemStyle}
            >
              <div className={item.id ? 'row' : 'row rounded pb-2 pt-2'} style={item.id ? {} : { backgroundColor: '#f8d7da' }} key={item.id}>
                <div className="col-2">
                  <select
                    key={item.key}
                    name={item.type}
                    value={item.type}
                    onChange={(e) => handleFieldChange(e, item, 'type')}
                    className="model_form_dropdown"
                  >
                    <option value=""> </option>
                    <option value="checkbox-group">Multiselect
                    </option>
                    <option value="textarea">Text</option>
                    <option value="number">Number</option>
                    <option value="header">Heading</option>
                    <option value="select">Select</option>
                    <option value="text">Line</option>
                    <option value="date">Date</option>
                  </select>
                </div>
                <div className="col-2">
                  {inputRenderHelper(item.type, item)}
                </div>
                <div className="col-1">
                  <input
                    key={item.key}
                    type="checkbox"
                    name={item.active}
                    checked={item.active}
                    onChange={(e) => handleCheckboxChange(e, item, 'active')}
                  />
                </div>
                <div className="col-1">
                  <input
                    key={item.key}
                    type="checkbox"
                    name={item.encrypt}
                    checked={item.encrypt}
                    onChange={(e) => handleCheckboxChange(e, item, 'encrypt')}
                  />
                </div>
                <div className="col-1">
                  <input
                    key={item.key}
                    type="checkbox"
                    name={item.required}
                    checked={item.required}
                    onChange={(e) => handleCheckboxChange(e, item, 'required')}
                    className="display-required"
                  />
                </div>
                {isManager ? (
                  <div className="col-2">
                    <button
                      type="button"
                      onClick={() => handleOpenDeleteModal(item)}
                      className="btn btn-outline-danger"
                    >
                      Remove
                    </button>
                  </div>
                ) : <></>}
              </div>
            </DraggableItem>
          ))}
        />
      </div>

      <div className="d-flex justify-content-between mt-2">
        <button type="button" className="btn btn-outline-primary" onClick={handleAddField}>Add field</button>
        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Save changes</button>
      </div>
    </>
  );
};

CustomFieldFormEditPage.propTypes = {
  modelFormId: PropTypes.number.isRequired,
  modelFormName: PropTypes.string.isRequired,
  modelFormItems: PropTypes.array.isRequired,
  isManager: PropTypes.bool.isRequired,
  associatedObjects: PropTypes.array.isRequired,
};

export default withContextProvider(ToastProvider)(CustomFieldFormEditPage);
