import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faMap } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from '../../components/Sidebar';
import { useDirectionsService } from '../../hooks/useDirectionsService';
import VamosMap from '../../components/VamosMap';
import DirectionsSegments from '../../components/VamosMap/DirectionsSegment';
import { withRoutesContext } from '../../hoc';
import { DialogsProvider } from '../../providers';
import { useRoutes, useToasts } from '../../hooks';
import ToggleSwitch from '../../components/ToggleSwitch';
import SitesInMarkers from '../../components/VamosMap/SitesInMarkers';
import DirectionButtons, { renderDirectionsPanel } from '../../components/VamosMap/DirectionsButton';

const Routes = () => {
  // Hook useRoute to have access to RoutesContext
  const { currentRoute, sites, addStop } = useRoutes();
  const [updatedSites, setUpdatedSites] = useState([]);

  // Hook Toast
  const { showToast } = useToasts();

  // State to handle array of coordinates
  const [coordinates, setCoordinates] = useState([]);

  // State to handle map mode
  const [isEnabled, setIsEnabled] = useState(true);

  // Hook to handle direction services
  const { directions } = useDirectionsService(coordinates);

  const currentPage = window.location.toString();

  const onChangeMapMode = () => {
    // this condition is added bc we do not want to try to craete a waypoint if there is not more than one stop
    if (currentRoute.stops.length < 2) {
      showToast({ message: 'There must be more than one stop to see a path. Please add another stop', type: 'error' });
      setIsEnabled(false);
    } else {
      setIsEnabled(!isEnabled);
    }
  };

  const [isBoundsChanged, setBoundsChanged] = useState(false);
  const [isButtonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(currentPage);
    if (currentSearchParams.get('commit') === 'Search') {
      setIsEnabled(false);
    }
  }, [currentPage]);

  // Updates stops when currentRoute.stops are changes
  useEffect(() => {
    if (currentRoute.stops.length < 2) {
      setIsEnabled(false);
    }
  }, [currentRoute.stops]);

  // To create the coords of a site from the existing lat and long and update the existing sites fields
  useEffect(() => {
    if (sites && sites.length) {
      const sitesWithCoords = sites.map((site) => ({ coords: { lat: site.latitude, lng: site.longitude }, ...site }));
      setUpdatedSites(sitesWithCoords);
    }
  }, [sites]);

  useEffect(() => {
    if (currentRoute?.stops) {
      // Creates array of coordinates with stops data
      setCoordinates(currentRoute.stops.map((stop) => ({ lat: stop.location.latitude, lng: stop.location.longitude })));
    }
  }, [currentRoute.stops]);

  return (
    <DialogsProvider>
      <Container>
        <Row className="route-edit-page-row">
          <Col md={12} lg={8}>
            <VamosMap isEnabled={isEnabled} setIsEnabled={setIsEnabled} isBoundsChanged={isBoundsChanged} isButtonClicked={isButtonClicked} setButtonClicked={setButtonClicked}>
              <DirectionsSegments directions={directions} />
              {
                isEnabled ? (<DirectionsSegments directions={directions} />)
                  : (<SitesInMarkers sitesArray={updatedSites} addStop={addStop} setBoundsChanged={setBoundsChanged} isButtonClicked={isButtonClicked} setButtonClicked={setButtonClicked} />)
              }
            </VamosMap>
            <span className="map-view-style">Map View</span>
            <FontAwesomeIcon icon={faSearch} className="searchIcon" />
            <span>Search</span>
            <ToggleSwitch onChange={onChangeMapMode} isEnabled={isEnabled} />
            <span>Path</span>
            <FontAwesomeIcon icon={faMap} className="mapIcon path-image-style" />
            {/* Instead of always rendering an empty div, now renders an accordion panel with div inside when directions is enabled */}
          </Col>
          <Col md={12} lg={4}>
            <Sidebar route={currentRoute} />
            <DirectionButtons isEnabled={isEnabled} setIsEnabled={setIsEnabled} />
          </Col>
        </Row>
        <br />
        <Row className="route-edit-page-row">
          <Col md={12} lg={8}>
            {renderDirectionsPanel(isEnabled)}
          </Col>
        </Row>
      </Container>
    </DialogsProvider>
  );
};

// Since project doesn't have Redux and either have a React entry file to host the entire app
// I created a HOC to allow components have access to the context.
// This way we will avoid props driling and integration between components and developers
// will be easier.
export default withRoutesContext(Routes);
